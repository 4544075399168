import React, { useEffect } from 'react'
import { Layout } from "../../components/layout"
import { Offerings } from '../../components/offering-section'
import { Section } from '../../components/section'
import { useLanguage, passLanguage, getLanguageDirection } from '../../components/language-context'
import { Row, RowText, ListWrapper, Spacer, RowLeft, RowRight, smoothScrollTo } from '../../components/offering-common-components';

import measure1DesktopImage from '../../images/offering/measure/desktop/measure-1@2x.png'
import measure2DesktopImage from '../../images/offering/measure/desktop/measure-2@2x.png'
import measure3DesktopImage from '../../images/offering/measure/desktop/measure-3@2x.png'
import measure4DesktopImage from '../../images/offering/measure/desktop/measure-4@2x.png'
import measure5DesktopImage from '../../images/offering/measure/desktop/measure-5@2x.png'
import measure6DesktopImage from '../../images/offering/measure/desktop/measure-6@2x.png'

import measure1MobileImage from '../../images/offering/measure/mobile/measure-1@2x.png'
import measure2MobileImage from '../../images/offering/measure/mobile/measure-2@2x.png'
import measure3MobileImage from '../../images/offering/measure/mobile/measure-3@2x.png'
import measure4MobileImage from '../../images/offering/measure/mobile/measure-4@2x.png'
import measure5MobileImage from '../../images/offering/measure/mobile/measure-5@2x.png'
import measure6MobileImage from '../../images/offering/measure/mobile/measure-6@2x.png'

const imagesDesktop = [measure1DesktopImage, measure2DesktopImage, measure3DesktopImage, measure4DesktopImage, measure5DesktopImage, measure6DesktopImage];
const imagesMobile = [measure1MobileImage, measure2MobileImage, measure3MobileImage, measure4MobileImage, measure5MobileImage, measure6MobileImage];


const IndexPageBase = useLanguage(({location, msg, lang, noScroll}) => {
  const paddingMainSection = {
    mobile: "10px 0px"
  }
  const sectionHeight = {
    mobile: '58px',
    desktop: '122px'
  }
  
  useEffect(() => {
    if (noScroll) return;
    smoothScrollTo("#measure-section");
  })
  
  return (
    <Layout location={location}>
      <Section title={msg('home-offering')} lang={lang}>
        <Offerings page='measure'/>
      </Section>
      <Section title={msg('measure-title')} showTitleLine={false} showLine={true} lineColor="#38b379" titleHeight={sectionHeight} lang={lang} id="measure-section">
        <Row margin="0">
          <RowText>
          { msg('measure-text') }
          </RowText>
        </Row>
      </Section>
      <Section padding={paddingMainSection}>
        <RowRight margin={'15px 0 10px 0'} title={msg('measure-1-title')} imageIndex={0} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('measure-1-text') }
          <ListWrapper>
            <ul>
              <li>{ msg('measure-1-text-1') }</li>
              <li>{ msg('measure-1-text-2') }</li>
              <li>{ msg('measure-1-text-3') }</li>
              <li>{ msg('measure-1-text-4') }</li>
            </ul>
          </ListWrapper>
        </RowRight>

        <RowLeft title={msg('measure-2-title')} imageIndex={1} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('measure-2-text') }
        </RowLeft>

        <RowRight title={msg('measure-3-title')} imageIndex={2} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('measure-3-text') }
        </RowRight>

        <RowLeft title={msg('measure-4-title')} imageIndex={3} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('measure-4-text') }
          <ListWrapper rightAlign={getLanguageDirection(lang) === 'ltr'}>
            <ul>
              <li>{ msg('measure-4-text-1') }</li>
              <li>{ msg('measure-4-text-2') }</li>
              <li>{ msg('measure-4-text-3') }</li>
              <li>{ msg('measure-4-text-4') }</li>
            </ul>
          </ListWrapper>
        </RowLeft>

        <RowRight title={msg('measure-5-title')} imageIndex={4} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('measure-5-text') }
        </RowRight>

        <RowLeft title={msg('measure-6-title')} imageIndex={5} imagesDesktop={imagesDesktop} imagesMobile={imagesMobile}>
          { msg('measure-6-text') }
        </RowLeft>
      </Section>
      <Spacer />
    </Layout>
  )}
);

const MeasurePage = passLanguage(IndexPageBase)

export default MeasurePage

